import React, { useState } from 'react';
import styled from 'styled-components';
import SideMenu from '../../components/organisms/SideMenu';
import TopMenu from '../../components/organisms/TopMenu';
import { device } from '../../utils/device';
import HorizontalMenu from '../../components/organisms/HorizontalMenu';
import { Helmet } from 'react-helmet';
import { Form, Input, Button, Card, Select, message } from 'antd';
import theme from '../../theme';
import { useMutation } from '@tanstack/react-query';
import { instanceApi } from 'services/Base/base.instance.services';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
	width: 100%;
	display: flex;
`;

const TopSection = styled.div``;

const SideMenuSection = styled.div`
	position: fixed;
	@media ${device.ipadL} {
		display: none;
	}
`;

const MobileMenu = styled.div`
	display: none;
	width: 100%;
	@media ${device.ipadL} {
		display: block;
	}
`;

const notificationTypes = {
	SYSTEM: 'system',
	FOLLOW: 'follow',
	LIKE: 'like',
	COMMENT: 'comment',
	MENTION: 'mention',
	WORK: 'work',
	EPISODE: 'episode',
	SHOWCASE: 'showcase',
	CHAT: 'chat',
	POST_LIKE: 'postLike',
	POST_MENTION: 'postMention',
	POST_COMMENT: 'postComment',
	POST_COMMENT_REPLY: 'postCommentReply',
	POST_COMMENT_MENTION: 'postCommentMention',
	RATE: 'rate',
	NEW_EPISODE: 'newEpisode',
	DONATE: 'donate',
	INLINE_COMMENT: 'inlineComment',
	GROUP_FULL: 'groupFull',
	NEW_MESSAGE: 'newMessage',
	SHOWCASE_ACCEPTED: 'showcaseAccepted',
};

const CreateNotificationContent = () => {
	const [form] = Form.useForm();
	const [selectedType, setSelectedType] = useState(notificationTypes.SYSTEM);
	const history = useHistory();

	const { mutate: createNotification, isLoading } = useMutation({
		mutationFn: data => instanceApi().post('/notifications/global', data),
		onSuccess: () => {
			message.success('Bildirim başarıyla oluşturuldu');
			history.push('/notifications');
		},
		onError: error => {
			message.error('Bildirim oluşturulurken bir hata oluştu');
		},
	});

	const onFinish = values => {
		// Boş string olan değerleri null'a çevir
		const data = Object.keys(values).reduce((acc, key) => {
			acc[key] = values[key] === '' ? null : values[key];
			return acc;
		}, {});

		createNotification(data);
	};

	// Bildirim tipine göre ek alanları belirle
	const getAdditionalFields = () => {
		switch (selectedType) {
			case notificationTypes.RATE:
			case notificationTypes.LIKE:
			case notificationTypes.NEW_EPISODE:
			case notificationTypes.EPISODE:
			case notificationTypes.WORK:
				return (
					<>
						<Form.Item name="subjectWork" label="Kitap ID">
							<Input placeholder="Kitap ID" />
						</Form.Item>
						<Form.Item
							name="subjectEpisode"
							label="Bölüm ID (Opsiyonel)">
							<Input placeholder="Bölüm ID" />
						</Form.Item>
					</>
				);

			case notificationTypes.POST_LIKE:
			case notificationTypes.POST_MENTION:
				return (
					<Form.Item name="subjectPost" label="Gönderi ID">
						<Input placeholder="Gönderi ID" />
					</Form.Item>
				);

			case notificationTypes.POST_COMMENT:
			case notificationTypes.POST_COMMENT_REPLY:
			case notificationTypes.POST_COMMENT_MENTION:
				return (
					<Form.Item name="subject" label="Yorum ID">
						<Input placeholder="Yorum ID" />
					</Form.Item>
				);

			case notificationTypes.FOLLOW:
			case notificationTypes.DONATE:
				return (
					<Form.Item name="subjectUser" label="Kullanıcı ID">
						<Input placeholder="Kullanıcı ID" />
					</Form.Item>
				);

			case notificationTypes.COMMENT:
			case notificationTypes.COMMENT_MENTION:
			case notificationTypes.INLINE_COMMENT:
				return (
					<Form.Item name="subject" label="Yorum ID">
						<Input placeholder="Yorum ID" />
					</Form.Item>
				);

			case notificationTypes.GROUP_FULL:
			case notificationTypes.NEW_MESSAGE:
				return (
					<Form.Item name="roomId" label="Sohbet Odası ID">
						<Input placeholder="Sohbet Odası ID" />
					</Form.Item>
				);

			default:
				return null;
		}
	};

	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 20,
				}}>
				<div>
					<h2
						style={{
							fontSize: 29,
							color: theme.darkBlue,
							marginBottom: 5,
						}}>
						Yeni Bildirim Oluştur
					</h2>
					<label style={{ fontSize: 15, color: theme.lightBlue }}>
						Tüm kullanıcılara gösterilecek yeni bir bildirim
						oluşturun.
					</label>
				</div>
			</div>

			<Card>
				<Form
					form={form}
					layout="vertical"
					onFinish={onFinish}
					initialValues={{
						type: notificationTypes.SYSTEM,
					}}>
					<Form.Item
						name="title"
						label="Başlık"
						rules={[
							{ required: true, message: 'Lütfen başlık girin' },
						]}>
						<Input placeholder="Bildirim başlığı" />
					</Form.Item>

					<Form.Item
						name="message"
						label="Mesaj"
						rules={[
							{ required: true, message: 'Lütfen mesaj girin' },
						]}>
						<Input.TextArea
							rows={4}
							placeholder="Bildirim mesajı"
						/>
					</Form.Item>

					<Form.Item
						name="type"
						label="Bildirim Tipi"
						rules={[
							{
								required: true,
								message: 'Lütfen bildirim tipi seçin',
							},
						]}>
						<Select onChange={value => setSelectedType(value)}>
							{Object.entries(notificationTypes).map(
								([key, value]) => (
									<Select.Option key={value} value={value}>
										{key}
									</Select.Option>
								)
							)}
						</Select>
					</Form.Item>

					<Form.Item name="link" label="Link (Opsiyonel)">
						<Input placeholder="Bildirime tıklandığında yönlendirilecek harici link" />
					</Form.Item>

					{getAdditionalFields()}

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							loading={isLoading}
							style={{ marginRight: 10 }}>
							Oluştur
						</Button>
						<Button onClick={() => history.push('/notifications')}>
							İptal
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</div>
	);
};

const CreateNotificationPage = () => {
	const [isToogleActive, setIsToogleActive] = useState(false);
	const marginLeft = isToogleActive ? 90 : 270;

	const Content = styled.div`
		width: 100%;
		margin-top: 100px;
		margin-left: ${marginLeft}px;
		margin-right: 30px;
		@media ${device.ipadL} {
			margin-left: 20px;
		}
	`;

	return (
		<>
			<Helmet>
				<title>Çizgi App - Admin Panel</title>
			</Helmet>
			<TopSection>
				<TopMenu setIsToogleActive={setIsToogleActive} />
			</TopSection>
			<MobileMenu>
				<HorizontalMenu isToogleActive={isToogleActive} />
			</MobileMenu>
			<Container>
				<SideMenuSection>
					<SideMenu
						sideNavTheme={'SIDE_NAV_LIGHT'}
						isToogleActive={isToogleActive}
					/>
				</SideMenuSection>
				<Content>
					<CreateNotificationContent />
				</Content>
			</Container>
		</>
	);
};

export default CreateNotificationPage;
