import {
	ApartmentOutlined,
	BankOutlined,
	BookOutlined,
	ContactsOutlined,
	CrownOutlined,
	DashboardOutlined,
	DotChartOutlined,
	EuroCircleOutlined,
	FileAddOutlined,
	SettingOutlined,
	StarOutlined,
	UserOutlined,
	UserSwitchOutlined,
	WechatOutlined,
	TrophyOutlined,
} from '@ant-design/icons';

const Dashboard = [
	{
		key: 'dashboard',
		path: `/dashboard`,
		title: 'Gösterge',
		icon: DashboardOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const Users = [
	{
		key: 'users',
		path: `/users`,
		title: 'Kullanıcılar',
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const Works = [
	{
		key: 'works',
		path: `/works`,
		title: 'Kitaplar',
		icon: BookOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: 'create-episode-from-drive',
				path: `/episodes/create-from-drive`,
				title: "Drive'dan Bölüm Oluştur",
				icon: FileAddOutlined,
				breadcrumb: false,
			},
		],
	},
];

const SystemUsers = [
	{
		key: 'system-users',
		path: `/system-users`,
		title: 'Sistem Kullanıcıları',
		icon: UserOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const Settings = [
	{
		key: 'settings',
		path: `/settings`,
		title: 'Ayarlar',
		icon: SettingOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const Avatars = [
	{
		key: 'avatars',
		path: `/avatars`,
		title: 'Avatarlar',
		icon: StarOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const Sliders = [
	{
		key: 'sliders',
		path: `/sliders`,
		title: 'Slider',
		icon: ApartmentOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const ActiveSliders = [
	{
		key: 'active-sliders',
		path: `/active-sliders`,
		title: 'Aktif Slider',
		icon: ApartmentOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const Categories = [
	{
		key: 'categories',
		path: `/categories`,
		title: 'Kategoriler',
		icon: CrownOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const Policies = [
	{
		key: 'policies',
		path: `/policies`,
		title: 'Politikalar',
		icon: BankOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const TermsOfUse = [
	{
		key: 'terms-of-use',
		path: `/terms-of-use`,
		title: 'Kullanım Koşulları',
		icon: BankOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const HomeList = [
	{
		key: 'home-lists',
		path: `/home-lists`,
		title: 'Anasayfa Listeleri',
		icon: DotChartOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const Reports = [
	{
		key: 'reports',
		path: `/reports`,
		title: 'Raporlar',
		icon: BankOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const SocialPosts = [
	{
		key: 'posts',
		path: `/social-posts`,
		title: 'Gönderiler',
		icon: UserSwitchOutlined,
		breadcrumb: false,
		submenu: [],
	},
];
const Moderation = [
	{
		key: 'moderation',
		path: `/moderation`,
		title: 'Moderasyon',
		icon: UserSwitchOutlined,
		breadcrumb: false,
		submenu: [],
	},
];
const ShowcaseRequests = [
	{
		key: 'showcase-requests',
		path: `/showcase-requests`,
		title: 'Vitrin Talepleri',
		icon: ContactsOutlined,
		breadcrumb: false,
		submenu: [],
	},
];
const ChatBot = [
	{
		key: 'chatbot',
		path: `/chatbot`,
		title: 'Chatbot',
		icon: WechatOutlined,
		breadcrumb: false,
		submenu: [],
	},
];
const MonetizationRequests = [
	{
		key: 'monetizations',
		path: `/monetizations`,
		title: 'Ödeme Talepleri',
		icon: EuroCircleOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const CreateEpisodeFromDrive = [
	{
		key: 'create-episode-from-drive',
		path: `/episodes/create-from-drive`,
		title: 'Çizgi Roman Oluştur',
		icon: FileAddOutlined,
		breadcrumb: false,
	},
];

const ReaderScore = [
	{
		key: 'reader-scores',
		path: `/reader-scores`,
		title: 'Okur Sıralaması',
		icon: TrophyOutlined,
		breadcrumb: false,
		submenu: [],
	},
];

const Notifications = [
	{
		key: 'notifications',
		path: `/notifications`,
		title: 'Bildirimler',
	},
];

const LockedEpisodesStats = [
	{
		key: 'locked-episodes-stats',
		path: `/locked-episodes-stats`,
		title: 'Kilitli Bölüm İstatistikleri',
	},
];

const navigationConfig = [
	...Dashboard,
	...Users,
	...SystemUsers,
	...SocialPosts,
	...ReaderScore,
	...Notifications,
	...ShowcaseRequests,
	...ChatBot,
	...Moderation,
	...MonetizationRequests,
	...Works,
	...LockedEpisodesStats,
	...CreateEpisodeFromDrive,
	...HomeList,
	...Categories,
	...Avatars,
	...Sliders,
	...ActiveSliders,
	...Policies,
	...Reports,

	...Settings,
];

export default navigationConfig;
