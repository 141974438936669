import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { device } from '../../utils/device';
import TopMenu from '../../components/organisms/TopMenu';
import SideMenu from '../../components/organisms/SideMenu';
import HorizontalMenu from '../../components/organisms/HorizontalMenu';
import {
	Card,
	DatePicker,
	Button,
	Table,
	Statistic,
	Row,
	Col,
	List,
	Tag,
	Spin,
	Space,
} from 'antd';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { instanceApi } from 'services/Base/base.instance.services';
import { getStrVal } from 'utils/string';
import { FiSearch, FiDownload } from 'react-icons/fi';

const PageContainer = styled.div`
	width: 100%;
	display: flex;
`;

const TopSection = styled.div``;

const SideMenuSection = styled.div`
	position: fixed;

	@media ${device.ipadL} {
		display: none;
	}
`;

const MobileMenu = styled.div`
	display: none;
	width: 100%;

	@media ${device.ipadL} {
		display: block;
	}
`;

const Title = styled.h1`
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 24px;
`;

const SubTitle = styled.h2`
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 16px;
`;

const LockedEpisodesStatsPage = () => {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [stats, setStats] = useState(null);
	const [isToogleActive, setIsToogleActive] = useState(false);

	const marginLeft = isToogleActive ? 90 : 270;

	const Content = styled.div`
		width: 100%;
		margin-top: 100px;
		margin-left: ${marginLeft}px;
		margin-right: 30px;
		padding: 24px;

		@media ${device.ipadL} {
			margin-left: 20px;
		}
	`;

	const handleSearch = async () => {
		try {
			setLoading(true);
			const { data } = await instanceApi().get(
				'/admin/locked-episodes-stats',
				{
					params: {
						startDate: startDate?.format('YYYY-MM-DD'),
						endDate: endDate?.format('YYYY-MM-DD'),
					},
				}
			);
			setStats(data);
		} catch (error) {
			message.error('İstatistikler alınırken bir hata oluştu.');
		} finally {
			setLoading(false);
		}
	};

	const handleDownloadCsv = () => {
		if (!stats) return;

		const monthlyData = stats.unlockStats.monthlyUnlocks.map(month => ({
			Yıl: month.year,
			Ay: format(new Date(2024, month.month - 1), 'MMMM', { locale: tr }),
			'Açılma Sayısı': month.unlockCount,
			'Harcanan Token': month.coinsSpent,
		}));

		const episodesData = stats.mostUnlockedEpisodes.map(
			(episode, index) => ({
				Sıra: index + 1,
				'Eser Adı': getStrVal(episode, 'workTitle').replace(
					/\/|\\/g,
					''
				),
				'Bölüm Adı': getStrVal(episode, 'episodeTitle').replace(
					/\/|\\/g,
					''
				),
				'Bölüm Sırası': episode.episodeOrder,
				'Açılma Sayısı': episode.unlockCount,
				'Toplam Token': episode.totalCoins,
			})
		);

		const monthlyRows = [
			Object.keys(monthlyData[0] || {}).join(','),
			...monthlyData.map(row => Object.values(row).join(',')),
		];

		const episodesRows = [
			Object.keys(episodesData[0] || {}).join(','),
			...episodesData.map(row => Object.values(row).join(',')),
		];

		const csvContent = [
			'Genel İstatistikler',
			`Toplam Kilitli Bölüm,${stats.totalLockedEpisodes}`,
			`Kilitli Bölüm İçeren Eser Sayısı,${stats.totalWorksWithLockedEpisodes}`,
			`Toplam Açılma Sayısı,${stats.unlockStats.totalUnlocks}`,
			`Toplam Harcanan Token,${stats.unlockStats.totalCoinsSpent}`,
			'',
			'Aylık İstatistikler',
			...monthlyRows,
			'',
			'En Çok Açılan Bölümler',
			...episodesRows,
		].join('\n');

		const blob = new Blob([csvContent], {
			type: 'text/csv;charset=utf-8;',
		});
		const link = document.createElement('a');
		const url = URL.createObjectURL(blob);
		link.setAttribute('href', url);
		link.setAttribute(
			'download',
			`kilitli-bolum-istatistikleri-${format(
				new Date(),
				'dd-MM-yyyy'
			)}.csv`
		);
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const monthlyColumns = [
		{
			title: 'Yıl',
			dataIndex: 'year',
			key: 'year',
		},
		{
			title: 'Ay',
			dataIndex: 'month',
			key: 'month',
			render: month =>
				format(new Date(2024, month - 1), 'MMMM', { locale: tr }),
		},
		{
			title: 'Açılma Sayısı',
			dataIndex: 'unlockCount',
			key: 'unlockCount',
			align: 'right',
			render: text => (
				<span style={{ color: '#52c41a', fontWeight: 'bold' }}>
					{text}
				</span>
			),
		},
		{
			title: 'Harcanan Token',
			dataIndex: 'coinsSpent',
			key: 'coinsSpent',
			align: 'right',
			render: text => (
				<span style={{ color: '#fa8c16', fontWeight: 'bold' }}>
					{text}
				</span>
			),
		},
	];

	const StatsContent = () => (
		<div>
			<Card>
				<Title>Kilitli Bölüm İstatistikleri</Title>
				<Space
					direction="vertical"
					size="large"
					style={{ width: '100%' }}>
					<Row gutter={[16, 16]}>
						<Col xs={24} md={8}>
							<DatePicker
								placeholder="Başlangıç Tarihi"
								style={{ width: '100%' }}
								value={startDate}
								onChange={setStartDate}
							/>
						</Col>
						<Col xs={24} md={8}>
							<DatePicker
								placeholder="Bitiş Tarihi"
								style={{ width: '100%' }}
								value={endDate}
								onChange={setEndDate}
							/>
						</Col>
						<Col xs={24} md={8}>
							<Space>
								<Button
									type="primary"
									icon={<FiSearch />}
									onClick={handleSearch}
									loading={loading}>
									Ara
								</Button>
								{stats && (
									<Button
										type="default"
										icon={<FiDownload />}
										onClick={handleDownloadCsv}>
										CSV İndir
									</Button>
								)}
							</Space>
						</Col>
					</Row>
				</Space>
			</Card>

			{loading ? (
				<div style={{ textAlign: 'center', padding: '40px' }}>
					<Spin size="large" />
				</div>
			) : stats ? (
				<Space
					direction="vertical"
					size="large"
					style={{ width: '100%', marginTop: '24px' }}>
					<Card>
						<Row gutter={[32, 32]}>
							<Col xs={24} sm={12} md={6}>
								<Statistic
									title="Toplam Kilitli Bölüm"
									value={stats.totalLockedEpisodes}
									valueStyle={{ color: '#1890ff' }}
								/>
							</Col>
							<Col xs={24} sm={12} md={6}>
								<Statistic
									title="Kilitli Bölüm İçeren Eser"
									value={stats.totalWorksWithLockedEpisodes}
									valueStyle={{ color: '#722ed1' }}
								/>
							</Col>
							<Col xs={24} sm={12} md={6}>
								<Statistic
									title="Toplam Açılma"
									value={stats.unlockStats.totalUnlocks}
									valueStyle={{ color: '#52c41a' }}
								/>
							</Col>
							<Col xs={24} sm={12} md={6}>
								<Statistic
									title="Toplam Harcanan Token"
									value={stats.unlockStats.totalCoinsSpent}
									valueStyle={{ color: '#fa8c16' }}
								/>
							</Col>
						</Row>
					</Card>

					<Card>
						<SubTitle>Aylık İstatistikler</SubTitle>
						<Table
							columns={monthlyColumns}
							dataSource={stats.unlockStats.monthlyUnlocks}
							rowKey={record => `${record.year}-${record.month}`}
							pagination={false}
						/>
					</Card>

					<Card>
						<SubTitle>En Çok Açılan Bölümler</SubTitle>
						<List
							dataSource={stats.mostUnlockedEpisodes}
							renderItem={(episode, index) => (
								<List.Item>
									<Row
										style={{ width: '100%' }}
										justify="space-between"
										align="middle">
										<Col>
											<Space direction="vertical">
												<Space>
													<Tag color="purple">
														{index + 1}
													</Tag>
													<span
														style={{
															fontWeight: 'bold',
															fontSize: '16px',
														}}>
														{getStrVal(
															episode,
															'workTitle'
														)}
													</span>
												</Space>
												<span
													style={{
														color: 'rgba(0, 0, 0, 0.45)',
													}}>
													{getStrVal(
														episode,
														'episodeTitle'
													)}{' '}
													(Bölüm{' '}
													{episode.episodeOrder})
												</span>
											</Space>
										</Col>
										<Col>
											<Space
												direction="vertical"
												align="end">
												<span
													style={{
														color: '#52c41a',
														fontWeight: 'bold',
													}}>
													{episode.unlockCount} açılma
												</span>
												<span
													style={{
														color: '#fa8c16',
													}}>
													{episode.totalCoins} token
												</span>
											</Space>
										</Col>
									</Row>
								</List.Item>
							)}
						/>
					</Card>
				</Space>
			) : null}
		</div>
	);

	return (
		<>
			<Helmet>
				<title>Çizgi App - Kilitli Bölüm İstatistikleri</title>
			</Helmet>
			<TopSection>
				<TopMenu setIsToogleActive={setIsToogleActive} />
			</TopSection>
			<MobileMenu>
				<HorizontalMenu isToogleActive={isToogleActive} />
			</MobileMenu>
			<PageContainer>
				<SideMenuSection>
					<SideMenu
						sideNavTheme={'SIDE_NAV_LIGHT'}
						isToogleActive={isToogleActive}
					/>
				</SideMenuSection>
				<Content>
					<StatsContent />
				</Content>
			</PageContainer>
		</>
	);
};

export default LockedEpisodesStatsPage;
