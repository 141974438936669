import React, { useState } from 'react';
import styled from 'styled-components';
import SideMenu from '../../components/organisms/SideMenu';
import TopMenu from '../../components/organisms/TopMenu';
import { device } from '../../utils/device';
import HorizontalMenu from '../../components/organisms/HorizontalMenu';
import { Helmet } from 'react-helmet';
import { Table, DatePicker, Button } from 'antd';
import theme from '../../theme';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { instanceApi } from 'services/Base/base.instance.services';
import moment from 'moment';

const Container = styled.div`
	width: 100%;
	display: flex;
`;

const TopSection = styled.div``;

const SideMenuSection = styled.div`
	position: fixed;

	@media ${device.ipadL} {
		display: none;
	}
`;

const MobileMenu = styled.div`
	display: none;
	width: 100%;

	@media ${device.ipadL} {
		display: block;
	}
`;

const ReaderScoreContent = () => {
	const [selectedWeek, setSelectedWeek] = useState(moment());
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const history = useHistory();

	const { data: readerScores, isLoading } = useQuery({
		queryKey: ['reader-scores', selectedWeek, page, pageSize],
		queryFn: () =>
			instanceApi().get('/reader-scores', {
				params: {
					weekDate: selectedWeek.startOf('week').toISOString(),
					sortBy: 'readerScore:desc',
					page,
					limit: pageSize,
				},
			}),
	});

	const handleTableChange = pagination => {
		setPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const columns = [
		{
			title: 'Sıra',
			dataIndex: 'rank',
			key: 'rank',
			render: (_, __, index) => index + 1,
		},
		{
			title: 'Kullanıcı',
			dataIndex: ['user', 'username'],
			key: 'username',
		},
		{
			title: 'Puan',
			dataIndex: 'readerScore',
			key: 'readerScore',
		},
		{
			title: 'İşlemler',
			key: 'actions',
			render: (_, record) => (
				<Button
					type="primary"
					icon={<EyeOutlined />}
					onClick={() =>
						history.push(`/users/${record.user.id}/detail`)
					}
					size="small">
					Detay
				</Button>
			),
		},
	];

	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 20,
				}}>
				<div>
					<h2
						style={{
							fontSize: 29,
							color: theme.darkBlue,
							marginBottom: 5,
						}}>
						Okur Puanı Sıralaması
					</h2>
					<label style={{ fontSize: 15, color: theme.lightBlue }}>
						Haftalık okur puanı sıralamalarını
						görüntüleyebilirsiniz.
					</label>
				</div>
				<DatePicker.WeekPicker
					onChange={date => setSelectedWeek(date || moment())}
					value={selectedWeek}
					placeholder="Hafta Seç"
					style={{ width: 200 }}
				/>
			</div>
			<Table
				dataSource={readerScores?.data?.results || []}
				columns={columns}
				loading={isLoading}
				rowKey="_id"
				pagination={{
					total: readerScores?.data?.totalResults,
					pageSize: pageSize,
					current: page,
					showSizeChanger: true,
				}}
				onChange={handleTableChange}
			/>
		</div>
	);
};

const ReaderScorePage = () => {
	const [isToogleActive, setIsToogleActive] = useState(false);

	const marginLeft = isToogleActive ? 90 : 270;

	const Content = styled.div`
		width: 100%;
		margin-top: 100px;
		margin-left: ${marginLeft}px;
		margin-right: 30px;

		@media ${device.ipadL} {
			margin-left: 20px;
		}
	`;

	return (
		<>
			<Helmet>
				<title>Çizgi App - Admin Panel</title>
			</Helmet>
			<TopSection>
				<TopMenu setIsToogleActive={setIsToogleActive} />
			</TopSection>
			<MobileMenu>
				<HorizontalMenu isToogleActive={isToogleActive} />
			</MobileMenu>
			<Container>
				<SideMenuSection>
					<SideMenu
						sideNavTheme={'SIDE_NAV_LIGHT'}
						isToogleActive={isToogleActive}
					/>
				</SideMenuSection>
				<Content>
					<ReaderScoreContent />
				</Content>
			</Container>
		</>
	);
};

export default ReaderScorePage;
