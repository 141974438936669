import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Grid } from 'antd';
import Icon from '../../util-components/Icon';
import navigationConfig from '../../../configs/NavigationConfig';
import styled from 'styled-components';
import {
	ApartmentOutlined,
	BankOutlined,
	BookOutlined,
	CommentOutlined,
	ContactsOutlined,
	CrownOutlined,
	DashboardOutlined,
	DotChartOutlined,
	EuroCircleOutlined,
	SettingOutlined,
	StarOutlined,
	UserOutlined,
	UserSwitchOutlined,
	FileAddOutlined,
	TrophyOutlined,
	BellOutlined,
} from '@ant-design/icons';

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	width: 100%;
	padding-top: 70px;
	text-align: center;
`;

/**
 *
 * @param isToogleActive
 * @returns {JSX.Element}
 * @constructor
 */
const HorizontalMenu = ({ isToogleActive }) => {
	return (
		<>
			{isToogleActive && (
				<>
					<Container>
						<Menu
							theme={'dark'}
							mode="inline"
							style={{
								height: '100vh',
								borderRight: 0,
								width: '100%',
							}}
							className="hide-group-title">
							{navigationConfig.map(menu => (
								<Menu.Item key={menu.key}>
									{menu.icon ? (
										<Icon type={menu?.icon} />
									) : null}
									<span>{menu.title}</span>
									{menu.path ? <Link to={menu.path} /> : null}
								</Menu.Item>
							))}
							<Menu.Item key="notifications">
								<Icon type={BellOutlined} />
								<span>{'Bildirimler'}</span>
								<Link to="/notifications" />
							</Menu.Item>
						</Menu>
					</Container>
				</>
			)}
		</>
	);
};
export default HorizontalMenu;
