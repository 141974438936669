import { $axios, setToken } from '../Base/base.services';

export const login = user =>
	new Promise((resolve, reject) => {
		$axios
			.post('/auth/admin/login', {
				email: user.email,
				password: user.password,
			})
			.then(res => {
				sessionStorage.setItem('token', res.data.tokens.access.token);
				localStorage.setItem('token', res.data.tokens.access.token);
				localStorage.setItem('userId', res.data.user.id);
				setToken(res.data.tokens.access.token);
				resolve(res);
			})
			.catch(err => {
				console.log('err: ', err);
				reject(err?.response?.data);
			});
	});
