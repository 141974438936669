import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Table, Tooltip, Switch, message } from 'antd';
import { instanceApi } from 'services/Base/base.instance.services';
import {
	EyeOutlined,
	DownloadOutlined,
	FileTextOutlined,
	FilePdfOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Layout from 'components/common/Layout';
import pick from 'utils/pick';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const WorkDetailPage = ({ match }) => {
	const id = match.params.id;
	const [work, setWork] = useState(null);
	const [episodes, setEpisodes] = useState([]);
	const history = useHistory();
	const [isUpdating, setIsUpdating] = useState(false);
	const [isGeneratingHtml, setIsGeneratingHtml] = useState(false);
	const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

	useEffect(() => {
		fetchData();
	}, [id]);

	const fetchData = async () => {
		if (!id) return;
		const { data } = await instanceApi().get(`/works/${id}`);

		if (data) {
			setWork(data);
		}

		const { data: episodes } = await instanceApi().get(
			`/works/${id}/episodes?work=${id}&limit=999`
		);

		if (episodes?.results) {
			setEpisodes(episodes.results);
		}
	};

	const columns = [
		{
			title: `Başlık`,
			dataIndex: ['title', 'tr'],
		},
		{
			title: 'Yayında mı?',
			dataIndex: 'isActive',
			render: (_, elm) => {
				return <div>{elm?.isActive ? 'Yayında' : 'Taslak'}</div>;
			},
		},
		{
			title: 'Tarih',
			dataIndex: 'createdAt',
		},
		{
			title: '',
			dataIndex: 'actions',
			render: (_, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Görüntüle'}>
						<Button
							type="primary"
							className="mr-2"
							icon={<EyeOutlined />}
							onClick={() => {
								history.push(`/works/${id}/episodes/${elm.id}`);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	const onUpdate = async () => {
		try {
			const body = pick(work, ['highlight']);
			setIsUpdating(true);
			const { data } = await instanceApi().patch(
				`/works/${id}/manage`,
				body
			);
			message.success('Başarıyla kaydedildi.');
		} catch (error) {
			message.error(error);
		} finally {
			setIsUpdating(false);
		}
	};

	const generateWorkHtml = async () => {
		try {
			setIsGeneratingHtml(true);
			message.loading('HTML oluşturuluyor...');

			// API'den HTML içeriğini al
			const response = await instanceApi().get(
				`/admin/generate-work-html/${id}`,
				{
					responseType: 'blob', // Blob olarak yanıt al
				}
			);

			// Blob'u HTML olarak indir
			const blob = new Blob([response.data], { type: 'text/html' });
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${work?.title?.tr || 'eser'}.html`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			message.success('HTML başarıyla oluşturuldu ve indirildi.');
		} catch (error) {
			console.error('HTML oluşturma hatası:', error);
			message.error('HTML oluşturulurken bir hata oluştu.');
		} finally {
			setIsGeneratingHtml(false);
		}
	};

	const generateWorkPdf = async () => {
		try {
			setIsGeneratingPdf(true);
			message.loading('HTML içeriği hazırlanıyor...');

			// API'den HTML içeriğini al
			const response = await instanceApi().get(
				`/admin/generate-work-html/${id}`,
				{
					responseType: 'text', // Metin olarak yanıt al
				}
			);

			// Yeni bir pencere aç ve HTML içeriğini yükle
			const newWindow = window.open('', '_blank');
			if (!newWindow) {
				message.error(
					'Popup penceresi açılamadı. Lütfen popup engelleyiciyi devre dışı bırakın.'
				);
				setIsGeneratingPdf(false);
				return;
			}

			// HTML içeriğini yeni pencereye yaz
			newWindow.document.write(`
				<html>
					<head>
						<title>${work?.title?.tr || 'Eser'}</title>
						<style>
							body { 
								font-family: Arial, sans-serif; 
								margin: 20px; 
								line-height: 1.5;
								padding-bottom: 50px;
							}
							img { max-width: 100%; height: auto; }
							.print-instructions {
								position: fixed;
								bottom: 0;
								left: 0;
								right: 0;
								background: #f0f0f0;
								padding: 10px;
								text-align: center;
								border-top: 1px solid #ddd;
								font-weight: bold;
								z-index: 1000;
							}
							.print-button {
								background: #1890ff;
								color: white;
								border: none;
								padding: 8px 16px;
								border-radius: 4px;
								cursor: pointer;
								font-size: 14px;
								margin-left: 10px;
							}
							.print-button:hover {
								background: #40a9ff;
							}
							@media print {
								.print-instructions {
									display: none;
								}
							}
						</style>
					</head>
					<body>
						${response.data}
						<div class="print-instructions">
							PDF olarak kaydetmek için "Yazdır" özelliğini kullanın ve hedef olarak "PDF olarak kaydet" seçeneğini seçin
							<button class="print-button" onclick="window.print()">Yazdır</button>
						</div>
						<script>
							// Sayfa yüklendiğinde mesaj gönder
							window.onload = function() {
								window.opener.postMessage('html_loaded', '*');
							};
						</script>
					</body>
				</html>
			`);
			newWindow.document.close();

			// Mesaj dinleyicisi ekle
			const messageListener = event => {
				if (event.data === 'html_loaded') {
					// HTML yüklendi, yükleme mesajını kaldır
					message.success(
						'HTML içeriği başarıyla açıldı. Yazdır butonunu kullanarak PDF olarak kaydedebilirsiniz.'
					);
					setIsGeneratingPdf(false);
					// Dinleyiciyi kaldır
					window.removeEventListener('message', messageListener);
				}
			};
			window.addEventListener('message', messageListener);
		} catch (error) {
			console.error('HTML oluşturma hatası:', error);
			message.error('HTML oluşturulurken bir hata oluştu.');
			setIsGeneratingPdf(false);
		}
	};

	return (
		<Layout>
			<div>
				<div>
					<div className="d-flex justify-content-between align-items-center mb-3">
						<h6 className="text-muted text-uppercase mb-0">
							Genel Bilgiler
						</h6>
						<div>
							<Button
								type="primary"
								icon={<FileTextOutlined />}
								loading={isGeneratingHtml}
								style={{ marginRight: 10 }}
								onClick={generateWorkHtml}>
								HTML İndir
							</Button>
							<Button
								type="primary"
								icon={<FilePdfOutlined />}
								loading={isGeneratingPdf}
								onClick={generateWorkPdf}>
								PDF İndir
							</Button>
						</div>
					</div>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Id:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{id}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Başlık:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.title?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Giriş:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.introduction?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Slug:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.slug?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Yayın Tipi:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.publicationType}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Durumu:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.isActive === true ? 'Aktif' : 'Pasif'}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Okunma Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.readingCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Yorum Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.commentCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Beğeni Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.likesCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Toplam Değerlendirme:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.totalRating}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Toplam Değerlendirme Sayısı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.totalRatingCount}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Kategori Başlık:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.category?.title?.tr}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Yayınlanma Tarihi:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.publishDate}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Kitap Tipi:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.workType}
						</span>
					</p>
					<Divider />
					<h6 className="text-muted text-uppercase mb-3">
						Oluşturan Kişi Bilgileri
					</h6>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							İsim Soyisim:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.creator?.fullname}
						</span>
					</p>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Kullanıcı Adı:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.creator?.username}
						</span>
					</p>
					<Divider />
					<h6 className="text-muted text-uppercase mb-3">
						Tarih Bilgisi
					</h6>
					<p>
						<span className="text-muted" style={{ marginLeft: 10 }}>
							Tarih:{' '}
						</span>
						<span className="text-dark" style={{ marginLeft: 10 }}>
							{work?.createdAt}
						</span>
					</p>
					<Divider />
					<Form
						className="components-table-demo-control-bar"
						style={{ marginBottom: 16 }}>
						<Form.Item label="Öne Çıkar">
							<Switch
								checked={work?.highlight}
								onChange={val =>
									setWork({ ...work, highlight: val })
								}
							/>
						</Form.Item>
						<Button
							type="primary"
							htmlType="button"
							loading={isUpdating}
							disabled={isUpdating}
							onClick={onUpdate}>
							Kaydet
						</Button>
					</Form>
					<Divider />
					{episodes.length > 0 && (
						<div>
							<h6 className="text-muted text-uppercase mb-3">
								Bölümler
							</h6>
							<Table
								columns={columns}
								dataSource={episodes}
								pagination={false}
							/>
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default WorkDetailPage;
