import React from 'react';
import LoginForm from '../../../molecules/Auth/Login';
import LoginInfoCard from '../../../molecules/Auth/LoginInfoCard';
import { Row, Col } from 'antd';
import { Box, useMediaQuery } from '@chakra-ui/react';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Login = () => {
	const [isMobile] = useMediaQuery('(max-width: 768px)');

	return (
		<>
			<div
				style={{
					height: '100vh',
					width: '100vw',
					backgroundColor: '#f0f0f0',
					display: 'flex',
					justifyContent: 'center',
					// alignItems: 'center',
					flexDirection: 'column',
				}}>
				<div
					style={{
						flexDirection: 'column',
						backgroundColor: 'white',
						padding: '20px',
						borderRadius: '10px',
						width: isMobile ? '80vw' : '45vw',
						marginRight: 'auto',
						marginLeft: 'auto',
					}}>
					<LoginInfoCard />
					<LoginForm />
				</div>
			</div>
		</>
	);
};
export default Login;
