import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { device } from '../../../../utils/device';
import { useTranslation } from 'react-i18next';
const backgroundURL = '/img/others/img-17.jpg';

/**
 *
 * @type {{backgroundImage: string, backgroundSize: string, backgroundRepeat: string}}
 */
const backgroundStyle = {
	backgroundColor: '#333',
};

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const Container = styled.div`
	height: 100vh;

	@media ${device.ipadL} {
		display: none;
	}
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const LoginInfoCard = () => {
	const { t } = useTranslation();
	return (
		<div
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
			}}>
			<img
				className="img-fluid mt-3 mb-2"
				style={{
					width: '110px',
					alignSelf: 'center',
					backgroundColor: 'white',
				}}
				src="/img/logo.png"
				alt=""
			/>
			<h2 className="text-black text-center">Hoş Geldiniz</h2>
			<strong className="text-black text-center">
				Kami: Çizgi Studio
			</strong>
		</div>
	);
};
export default LoginInfoCard;
