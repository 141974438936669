import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Tooltip,
	Modal,
	notification,
	Form,
	Input,
	InputNumber,
} from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import { device } from '../../../../utils/device';
import { createHomeList } from '../../../../services/HomeList';
import { useHistory } from 'react-router-dom';
import { WorkLisQueriest } from 'queries/worksQueries';
import Filter from 'components/organisms/Filter';
import DataTable from 'components/table/DataTable';
import { useQueryClient } from '@tanstack/react-query';

const ButtonEpisode = styled.div`
	width: 30%;
	padding: 15px;
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const ButtonSections = styled.div`
	display: flex;
	width: 100%;
	@media ${device.mobileL} {
		display: block;
	}
`;

/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

const FilterEpisode = styled.div`
	width: 100%;
	padding: 15px;
`;

const FilterFormLine = styled.div`
	width: 100%;
	display: flex;
`;

const FilterSearchButtonEpisode = styled.div`
	margin-left: auto;
	margin-top: 30px;
`;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const WorkTable = () => {
	const queryClient = useQueryClient();
	const [deleteReason, setDeleteReason] = useState('');
	const history = useHistory();
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCategoryList, setTotalCategoryList] = useState([]);
	const [filters, setFilters] = useState({});
	const [sortByHeader, setSortByHeader] = useState();
	const [searchValue, setSearchValue] = useState('');
	const [works, setWorks] = useState([]);
	const [tempWorks, setTempWorks] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { confirm } = Modal;
	const {
		data,
		totalResults,
		fetchNextPage,
		fetchPreviousPage,
		currentPage,
		isFetching,
		refetch,
	} = WorkLisQueriest(page, pageSize, filters, sortByHeader, searchValue);

	useEffect(() => {
		if (history.action === 'POP') {
			const storedFilters = queryClient.getQueryData(['workFilters']);
			if (storedFilters) setFilters(storedFilters);
		}
	}, [history.action]);

	useEffect(() => {
		queryClient.setQueryData(['workFilters'], filters);
	}, [filters]);

	const handleDelete = (workId, reason) => {
		if (!reason.trim()) {
			return notification.error({
				message: 'Silme nedeni giriniz.',
			});
		}
		instanceApi()
			.delete(`/works/${workId}?reason=${reason.trim()}`)
			.then(res => {
				window.location = '/works';
			})
			.catch(err => {
				console.log('err: ', err);
			});
	};

	const deleteWork = workId => {
		const dialog = confirm({
			title: `Bu kitabı silmek istediğinize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				handleDelete(workId, deleteReason);
			},
			onCancel() {
				console.log('Cancel');
			},
			content: (
				<Input
					type="text"
					onChange={e => {
						setDeleteReason(e.target.value);
						dialog.update({
							onOk() {
								handleDelete(workId, e.target.value);
							},
						});
					}}
					placeholder="Silme nedeninizi giriniz (kullanıcıya bildirilecektir)."
				/>
			),
		});
	};
	const fetchTotalCategories = () => {
		instanceApi()
			.get('/categories?limit=1000')
			.then(res => {
				const data = res?.data?.results.map(x => ({
					...x,
					title: x?.title?.tr,
				}));
				setTotalCategoryList(data);
			})
			.catch(err => console.log(err));
	};

	const handleFilterChange = (key, value) => {
		setSearchValue('');
		setFilters(prev => {
			const column = filterList?.find(col => col?.id === key);
			const selectedItem = column?.data?.find(
				item => item?.title === value
			);
			if (!selectedItem) {
				const updatedFilters = { ...prev };
				delete updatedFilters[key];
				return updatedFilters;
			}
			return {
				...prev,
				[key]: selectedItem?.id ?? value,
			};
		});
	};
	const filterList = [
		{
			id: 'isActive',
			header: 'Aktif mi',
			data: [
				{ title: 'Aktif', id: true },
				{ title: 'Pasif', id: false },
			],
		},
		{
			id: 'isCompleted',
			header: 'Tamamlandı mı',
			data: [
				{ title: 'Tamamlandı', id: true },
				{ title: 'Devam Ediyor', id: false },
			],
		},
		{
			id: 'language',
			header: 'Dil',
			data: [
				{ title: 'İngilizce', id: 'en' },
				{ title: 'Türkçe', id: 'tr' },
			],
		},
		{
			id: 'category',
			header: 'Kategori',
			data: totalCategoryList,
		},
		{
			id: 'publicationType',
			header: 'Yayın Türü',
			data: [
				{ title: 'Vitrin', id: 'showcase' },
				{ title: 'Normal', id: 'normal' },
			],
		},
		{
			id: 'workType',
			header: 'Kitap Tipi',
			data: [
				{ title: 'novel', id: 'novel' },
				{ title: 'drawing', id: 'drawing' },
			],
		},
		{
			id: 'isAllEpisodesLocked',
			header: 'Tüm Bölümler Kiliti',
			data: [
				{ title: 'Evet', id: true },
				{ title: 'Hayır', id: false },
			],
		},
		{
			id: 'isAdult',
			header: 'Yetişkin',
			data: [
				{ title: 'Evet', id: true },
				{ title: 'Hayır', id: false },
			],
		},
	];
	const columns = [
		{
			id: 'title',
			header: 'Başlık',
			accessorFn: row => row.title?.tr,
		},
		{
			id: 'category',
			header: 'Kategori',
			accessorFn: row => row.category?.title?.tr,
		},
		{
			id: 'publicationType',
			header: 'Yayın Türü',
			accessorFn: row =>
				row.publicationType === 'showcase' ? 'Vitrin' : 'Normal',
		},
		{
			id: 'workType',
			header: 'Kitap Tipi',
			accessorFn: row => row.workType,
		},
		{
			id: 'likesCount',
			header: 'Beğeni Sayısı',
			accessorFn: row => row.likesCount,
		},
		{
			id: 'readingCount',
			header: 'Okunma Sayısı',
			accessorFn: row => row.readingCount,
		},
		{
			id: 'commentCount',
			header: 'Yorum Sayısı',
			accessorFn: row => row.commentCount,
		},
		{
			id: 'totalRatingCount',
			header: 'Değerlendirme Sayısı',
			accessorFn: row => row.totalRatingCount,
		},
		{
			id: 'EpisodeCount',
			header: 'Eser Sayısı',
			accessorFn: row => row?.EpisodeCount,
		},
		{
			id: 'lockedEpisodesCount',
			header: 'Kiliti Bölüm Sayısı',
			accessorFn: row => row.lockedEpisodesCount,
		},
		{
			id: 'createdAt',
			header: 'Tarih',
			accessorFn: row => row.createdAt,
		},
		{
			id: 'actions',
			header: '',
			accessorFn: 'actions',
			type: 'actions',
			cell: ({ row }) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Görüntüle'}>
						<Button
							type="primary"
							className="mr-2"
							icon={<EyeOutlined />}
							onClick={() => {
								// showWorkDetail(elm);
								history.push(`/works/${row.original.id}`);
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Sil'}>
						<Button
							style={{ marginLeft: '12px' }}
							danger
							icon={<DeleteOutlined />}
							onClick={() => {
								deleteWork(row.original.id);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	useEffect(() => {
		fetchTotalCategories();
	}, []);

	const paginationOnChange = event => {
		setPage(event);
	};

	const setShowSizeChange = (event, size) => {
		setPageSize(size);
	};

	const rowSelection = id => {
		if (typeof id == 'string') {
			setSelectedRows(prevIds => {
				// Eğer id zaten varsa, onu çıkar
				if (prevIds.includes(id)) {
					return prevIds.filter(existingId => existingId !== id);
				}
				// Yoksa, ekle
				return [...prevIds, id];
			});
		} else {
			setSelectedRows(id);
		}
	};
	const addButtonOnClick = () => {
		setModalVisible(true);
		selectedRows.map(selectedRow => {
			if (!tempWorks.includes(selectedRow)) {
				tempWorks.push(selectedRow);
			}
		});
		console.log('tempWorks: ', tempWorks);

		tempWorks.map((tempWork, index) => {
			const data = {
				order: index + 1,
				work: tempWork,
			};
			works.push(data);
		});
	};
	const handleCancel = () => {
		setModalVisible(false);
	};
	const onFinish = values => {
		setLoading(true);

		const data = {
			title: values.title,
			description: values.description,
			order: values.order,
			isActive: true,
			works: works,
		};

		createHomeList(data)
			.then(res => {
				setLoading(false);
				notification.success({
					message: 'Liste başarılı bir şekilde oluşturuldu!',
				});
				setModalVisible(false);
			})
			.catch(err => {
				setLoading(false);
				console.log(err);
				notification.error({
					message: err.message,
				});
			});
	};
	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		notification.error({
			message: `Bilgileri kontrol edip tekrar deneyiniz!`,
		});
	};

	const checkFilter = () => {
		if (Object.keys(filters).length !== 0) {
			setIsModalOpen(true);
		}
	};
	const continueSearch = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<Modal
				title="Anasayfa Listesi İçin Gruplandırma Yap"
				visible={modalVisible}
				onCancel={handleCancel}
				footer={null}>
				<Form
					layout="vertical"
					name="login-form"
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}>
					<Form.Item
						name="title"
						label="Başlık"
						rules={[
							{
								required: true,
								message: 'Lütfen Başlık alanını girin.',
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						name="description"
						label="Açıklama"
						rules={[
							{
								required: true,
								message: 'Lütfen açıklama alanını girin.',
							},
						]}>
						<TextArea rows={'5'} />
					</Form.Item>

					<Form.Item
						name="order"
						label="Sıra"
						rules={[
							{
								required: true,
								message: 'Lütfen sıra alanını girin.',
							},
						]}>
						<InputNumber style={{ width: '100%' }} />
					</Form.Item>
					<Form.Item>
						<ButtonSections>
							<PrimaryButton>
								<Button
									style={{ width: '200px' }}
									type="primary"
									htmlType="submit"
									loading={loading}>
									Oluştur
								</Button>
							</PrimaryButton>
						</ButtonSections>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				title="Dikkat"
				visible={isModalOpen}
				onCancel={handleCancel}
				footer={null}>
				<p style={{ marginBottom: '20px' }}>
					Seçmiş olduğunuz filtreler kaldırılacak !
				</p>
				<div
					style={{
						display: 'flex',
						gap: '10px',
						justifyContent: 'center',
					}}>
					<button
						onClick={continueSearch}
						style={{
							padding: '10px 20px',
							backgroundColor: '#28a745',
							color: 'white',
							border: 'none',
							borderRadius: '4px',
							cursor: 'pointer',
						}}>
						Tamam
					</button>
				</div>
			</Modal>
			<Card>
				<Form.Item
					name="search"
					label="Eser İsmine Göre Ara"
					style={{ width: '300px' }}
					rules={[
						{
							required: false,
							message: 'Lütfen Arama alanını girin',
						},
					]}>
					<Input
						placeholder="Arama değeri girin..."
						onChange={e => setSearchValue(e.target.value)} // Input değerini state'e ata
						value={'searchValue'}
						onClick={() => checkFilter()}
					/>
				</Form.Item>
				<Button
					onClick={() => {
						setFilters({});
						refetch();
					}}
					style={{ width: '180px' }}
					type="default"
					htmlType="submit"
					//loading={filterLoading}
				>
					Ara
				</Button>
			</Card>
			<Card>
				<Filter
					columns={filterList}
					filters={filters}
					onFilterChange={handleFilterChange}
				/>
			</Card>

			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<DataTable
						data={data}
						totalResults={totalResults}
						fetchPreviousPage={fetchPreviousPage}
						fetchNextPage={fetchNextPage}
						currentPage={currentPage}
						isFetching={isFetching}
						columns={columns}
						paginationOnChange={paginationOnChange}
						setShowSizeChange={setShowSizeChange}
						defaultPageSize={pageSize}
						sortByHeader={sortByHeader}
						onSortChange={value =>
							setSortByHeader({ sortBy: value })
						}
						rowSelection={rowSelection}
						selectedRows={selectedRows}
					/>
					<ButtonEpisode>
						{selectedRows?.length > 0 && (
							<>
								<Button
									style={{ width: '120px' }}
									type="primary"
									onClick={() => addButtonOnClick()}
									block>
									Ekle
								</Button>
							</>
						)}
					</ButtonEpisode>
				</div>
			</Card>
		</>
	);
};
export default WorkTable;
