import React, { useState } from 'react';
import styled from 'styled-components';
import SideMenu from '../../components/organisms/SideMenu';
import TopMenu from '../../components/organisms/TopMenu';
import { device } from '../../utils/device';
import HorizontalMenu from '../../components/organisms/HorizontalMenu';
import { Helmet } from 'react-helmet';
import { Card, Descriptions, Spin } from 'antd';
import theme from '../../theme';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { instanceApi } from 'services/Base/base.instance.services';
import moment from 'moment';

const Container = styled.div`
	width: 100%;
	display: flex;
`;

const TopSection = styled.div``;

const SideMenuSection = styled.div`
	position: fixed;

	@media ${device.ipadL} {
		display: none;
	}
`;

const MobileMenu = styled.div`
	display: none;
	width: 100%;

	@media ${device.ipadL} {
		display: block;
	}
`;

const UserDetailContent = () => {
	const { userId } = useParams();

	const { data: userData, isLoading } = useQuery({
		queryKey: ['user-detail', userId],
		queryFn: () => instanceApi().get(`/users/${userId}`),
	});

	const user = userData?.data;

	if (isLoading) return <Spin size="large" />;

	return (
		<Card title="Kullanıcı Detayları">
			<Descriptions bordered column={1}>
				<Descriptions.Item label="Ad Soyad">
					{user?.fullname}
				</Descriptions.Item>
				<Descriptions.Item label="Kullanıcı Adı">
					{user?.username}
				</Descriptions.Item>
				<Descriptions.Item label="E-posta">
					{user?.email}
				</Descriptions.Item>
				<Descriptions.Item label="Okur Puanı">
					{user?.readerScore}
				</Descriptions.Item>
				<Descriptions.Item label="Takipçi Sayısı">
					{user?.followersCount}
				</Descriptions.Item>
				<Descriptions.Item label="Coin">{user?.coin}</Descriptions.Item>
				<Descriptions.Item label="Bağış Coin">
					{user?.donatedCoin}
				</Descriptions.Item>
				<Descriptions.Item label="Bakiye">
					{user?.balance}
				</Descriptions.Item>
				<Descriptions.Item label="Üyelik">
					{user?.membership}
				</Descriptions.Item>
				<Descriptions.Item label="Dil">
					{user?.language}
				</Descriptions.Item>
				<Descriptions.Item label="Yaş Tercihi">
					{user?.agePreference}
				</Descriptions.Item>
				<Descriptions.Item label="Ülke">
					{user?.country}
				</Descriptions.Item>
				<Descriptions.Item label="Doğum Tarihi">
					{user?.birthDate
						? moment(user.birthDate).format('DD/MM/YYYY')
						: '-'}
				</Descriptions.Item>
				<Descriptions.Item label="Son Giriş">
					{user?.lastLogin
						? moment(user.lastLogin).format('DD/MM/YYYY HH:mm')
						: '-'}
				</Descriptions.Item>
				<Descriptions.Item label="Son Aktivite">
					{user?.lastActivityAt
						? moment(user.lastActivityAt).format('DD/MM/YYYY HH:mm')
						: '-'}
				</Descriptions.Item>
				<Descriptions.Item label="Vitrin Kullanıcısı">
					{user?.hasPublishedAtShowcase ? 'Evet' : 'Hayır'}
				</Descriptions.Item>
				<Descriptions.Item label="E-posta Doğrulandı">
					{user?.isEmailVerified ? 'Evet' : 'Hayır'}
				</Descriptions.Item>
				<Descriptions.Item label="Davet Kodu">
					{user?.invitationCode}
				</Descriptions.Item>
				<Descriptions.Item label="Hakkında">
					{user?.bio || '-'}
				</Descriptions.Item>
				<Descriptions.Item label="IBAN">
					{user?.iban || '-'}
				</Descriptions.Item>
				<Descriptions.Item label="TC Kimlik No">
					{user?.tc || '-'}
				</Descriptions.Item>
				<Descriptions.Item label="Adres">
					{user?.address || '-'}
				</Descriptions.Item>
			</Descriptions>
		</Card>
	);
};

const UserDetailPage = () => {
	const [isToogleActive, setIsToogleActive] = useState(false);

	const marginLeft = isToogleActive ? 90 : 270;

	const Content = styled.div`
		width: 100%;
		margin-top: 100px;
		margin-left: ${marginLeft}px;
		margin-right: 30px;

		@media ${device.ipadL} {
			margin-left: 20px;
		}
	`;

	return (
		<>
			<Helmet>
				<title>Çizgi App - Admin Panel</title>
			</Helmet>
			<TopSection>
				<TopMenu setIsToogleActive={setIsToogleActive} />
			</TopSection>
			<MobileMenu>
				<HorizontalMenu isToogleActive={isToogleActive} />
			</MobileMenu>
			<Container>
				<SideMenuSection>
					<SideMenu
						sideNavTheme={'SIDE_NAV_LIGHT'}
						isToogleActive={isToogleActive}
					/>
				</SideMenuSection>
				<Content>
					<UserDetailContent />
				</Content>
			</Container>
		</>
	);
};

export default UserDetailPage;
