import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Tooltip,
	Modal,
	notification,
	Form,
	Input,
	Avatar,
	Select,
} from 'antd';
import {
	DeleteOutlined,
	EyeOutlined,
	EditOutlined,
	SketchOutlined,
} from '@ant-design/icons';
import { instanceApi } from '../../../../services/Base/base.instance.services';
import { UserDraver } from '../../../molecules/Draver';
import styled from 'styled-components';
import { device } from '../../../../utils/device';
import { UserLisQueriest } from 'queries/userQueries';
import Filter from 'components/organisms/Filter';
import DataTable from 'components/table/DataTable';
import { useQueryClient } from '@tanstack/react-query';

const FilterEpisode = styled.div`
	width: 100%;
	padding: 15px;
`;

const FilterFormLine = styled.div`
	width: 100%;
	display: flex;
`;

const FilterSearchButtonEpisode = styled.div`
	margin-left: auto;
	margin-top: 30px;
`;

const ButtonSections = styled.div`
	display: flex;
	width: 100%;
	@media ${device.mobileL} {
		display: block;
	}
`;
const GiftModalUserAvatarContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	border-bottom: 1px solid #ddd;
	padding-bottom: 10px;
	margin-bottom: 10px;
`;
const UserNameWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: gray;
`;
const GiftModalUserName = styled.div`
	color: gray;
`;
const GiftModalFullName = styled.div`
	color: #111;
`;
const GiftSelectBoxWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
const GiftSubmitButton = styled.div`
	display: flex;
	margin-top: 30px;
`;
/**
 *
 * @type {StyledComponent<"div", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const PrimaryButton = styled.div``;

const UserTable = () => {
	const queryClient = useQueryClient();
	const [visible, setVisible] = useState(false);
	const [role, setRole] = useState();
	const [fullName, setFullName] = useState();
	const [username, setUsername] = useState();
	const [email, setEmail] = useState();
	const [createdAt, setCreatedAt] = useState();
	const [gender, setGender] = useState();
	const [id, setId] = useState();
	const [total, setTotal] = useState();
	const [filterLoading, setFilterLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [filters, setFilters] = useState({});
	const [isGiftModalOpen, setIsGiftModalOpen] = useState(false);
	const [getSelectedUser, setSelectedUser] = useState();
	const [getProductList, setProductList] = useState();
	const [getSelectedGift, setSelectedGift] = useState();
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortByHeader, setSortByHeader] = useState();
	const [selectedRows, setSelectedRows] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { confirm } = Modal;

	const showUserDetail = user => {
		setVisible(true);
		setRole(user.role);
		setFullName(user.fullname);
		setUsername(user.username);
		setEmail(user.email);
		setCreatedAt(user.createdAt);
		setGender(user.gender);
		setId(user.id);
	};
	useEffect(() => {
		if (history.action === 'POP') {
			const storedFilters = queryClient.getQueryData(['userFilters']);
			if (storedFilters) setFilters(storedFilters);
		}
	}, [history.action]);

	useEffect(() => {
		queryClient.setQueryData(['userFilters'], filters);
	}, [filters]);

	const deleteUserProfile = userId => {
		confirm({
			title: `Bu kullanıcıyı silmek istediğinize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				instanceApi()
					.delete('/users/' + userId)
					.then(res => {
						window.location = '/users';
					})
					.catch(err => {
						console.log('err: ', err);
					});
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const editUserProfile = userId => {
		window.location = '/user/edit/' + userId;
	};
	const filterList = [
		{
			id: 'isEmailVerified',
			header: 'E-posta Doğrulandı mı',
			data: [
				{ title: 'Evet', id: true },
				{ title: 'Hayır', id: false },
			],
		},
		{
			id: 'role',
			header: 'Rol',
			data: [
				{ title: 'User', id: 'user' },
				{ title: 'Admin', id: 'admin' },
			],
		},
		{
			id: 'gender',
			header: 'Cinsiyet',
			data: [
				{ title: 'Erkek', id: 1 },
				{ title: 'Kadın', id: 0 },
			],
		},
		{
			id: 'hasPublishedAtShowcase',
			header: 'Vitrin Kullanıcısı',
			data: [
				{ title: 'Evet', id: true },
				{ title: 'Hayır', id: false },
			],
		},
		{
			id: 'membership',
			header: 'Üyelik Türü',
			data: [
				{ title: 'Premium', id: 'premium' },
				{ title: 'Normal', id: 'normal' },
			],
		},
	];
	const columns = [
		{
			header: `isim`,
			id: 'fullname',
			accessorFn: row => row.fullname,
		},
		{
			header: `Kullanıcı Adı`,
			id: 'username',
			accessorFn: row => row.username,
		},
		{
			header: `Email`,
			id: 'email',
			accessorFn: row => row.email,
		},
		{
			header: `Rol`,
			id: 'role',
			accessorFn: row => row.role,
		},
		{
			header: 'Tarih',
			id: 'createdAt',
			accessorFn: row => row.createdAt,
		},
		{
			header: 'Vitrin Kullanıcısı',
			id: 'hasPublishedAtShowcase',
			accessorFn: row => row.hasPublishedAtShowcase,
			cell: value => {
				return (
					<>
						{value?.row.original?.hasPublishedAtShowcase
							? 'Evet'
							: 'Hayır'}
					</>
				);
			},
		},
		{
			header: '',
			id: 'actions',
			cell: (x, elm) => (
				<div className="text-right d-flex justify-content-end">
					<Tooltip title={'Hediye Ver'}>
						<Button
							style={{ marginRight: 10 }}
							className="mr-5 gift-button"
							icon={<SketchOutlined style={{ fontSize: 20 }} />}
							onClick={() => {
								setIsGiftModalOpen(true);
								setSelectedUser(x?.row?.original);
								getProductListFromServer();
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Düzenle'}>
						<Button
							style={{ marginRight: 10 }}
							className="mr-5"
							icon={<EditOutlined />}
							onClick={() => {
								editUserProfile(x?.row?.original?.id);
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Detay'}>
						<Button
							style={{ marginRight: 10 }}
							className="mr-5"
							icon={<EyeOutlined />}
							onClick={() => {
								window.location = `/users/${x?.row?.original?.id}/detail`;
							}}
							size="small"
						/>
					</Tooltip>
					<Tooltip title={'Sil'}>
						<Button
							style={{ marginLeft: '12px' }}
							danger
							icon={<DeleteOutlined />}
							onClick={() => {
								deleteUserProfile(x?.row?.original?.id);
							}}
							size="small"
						/>
					</Tooltip>
				</div>
			),
		},
	];

	const {
		data,
		totalResults,
		fetchNextPage,
		fetchPreviousPage,
		currentPage,
		isFetching,
		refetch,
	} = UserLisQueriest(page, pageSize, filters, sortByHeader, searchValue);

	const closeVisible = () => {
		setVisible(false);
	};

	const getProductListFromServer = () => {
		instanceApi()
			.get('/products')
			.then(res => {
				setProductList(res.data.results);
			})
			.catch(err => {
				console.log('err: ', err);
			});
	};

	const handleOnGiftSelected = product => {
		setSelectedGift(product);
	};

	const hadnleSubmittingGift = () => {
		confirm({
			title: `${getSelectedUser.fullname} adli kullaniciya hediye olarak ${getSelectedGift} vermek istediginize emin misiniz?`,
			okText: `Evet`,
			okType: 'danger',
			cancelText: `Hayır`,
			onOk() {
				handleOnPresSubmitGiftButton();
			},
			onCancel() {
				setIsGiftModalOpen(false);
				setSelectedGift();
			},
		});
	};
	const handleOnPresSubmitGiftButton = () => {
		const data = {
			userId: getSelectedUser.id,
			productId: getSelectedGift,
		};
		instanceApi()
			.post('/purchases/give-gift', data)
			.then(res => {
				if (res.status == 200) {
					setIsGiftModalOpen(false);
					setSelectedGift();
					alert('Basarili, hediye onaylandi.');
				}
			})
			.catch(err => {
				setSelectedGift();
				alert('hata');
				setIsGiftModalOpen(false);
				console.log('err: ', err);
			});
	};

	const paginationOnChange = event => {
		setPage(event);
	};

	const setShowSizeChange = (event, size) => {
		setPageSize(size);
	};
	const rowSelection = id => {
		if (typeof id == 'string') {
			setSelectedRows(prevIds => {
				if (prevIds.includes(id)) {
					return prevIds.filter(existingId => existingId !== id);
				}
				return [...prevIds, id];
			});
		} else {
			setSelectedRows(id);
		}
	};
	const handleFilterChange = (key, value) => {
		setSearchValue('');
		setFilters(prev => {
			const column = filterList?.find(col => col?.id === key);
			const selectedItem = column?.data?.find(
				item => item?.title === value
			);
			if (!selectedItem) {
				const updatedFilters = { ...prev };
				delete updatedFilters[key];
				return updatedFilters;
			}
			return {
				...prev,
				[key]: selectedItem?.id ?? value,
			};
		});
	};
	const checkFilter = () => {
		if (Object.keys(filters).length !== 0) {
			setIsModalOpen(true);
		}
	};
	const continueSearch = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<FilterEpisode>
				<Card>
					<Form.Item
						name="search"
						label="Eser İsmine Göre Ara"
						style={{ width: '300px' }}
						rules={[
							{
								required: false,
								message: 'Lütfen Arama alanını girin',
							},
						]}>
						<Input
							placeholder="Arama değeri girin..."
							onChange={e => setSearchValue(e.target.value)} // Input değerini state'e ata
							value={'searchValue'}
							onClick={() => checkFilter()}
						/>
					</Form.Item>
					<Button
						onClick={() => {
							setFilters({});
							refetch();
						}}
						style={{ width: '180px' }}
						type="default"
						htmlType="submit"
						//loading={filterLoading}
					>
						Ara
					</Button>
				</Card>
			</FilterEpisode>
			<Modal
				title="Dikkat"
				visible={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				footer={null}>
				<p style={{ marginBottom: '20px' }}>
					Seçmiş olduğunuz filtreler kaldırılacak !
				</p>
				<div
					style={{
						display: 'flex',
						gap: '10px',
						justifyContent: 'center',
					}}>
					<button
						onClick={continueSearch}
						style={{
							padding: '10px 20px',
							backgroundColor: '#28a745',
							color: 'white',
							border: 'none',
							borderRadius: '4px',
							cursor: 'pointer',
						}}>
						Tamam
					</button>
				</div>
			</Modal>
			<Card>
				<Filter
					columns={filterList}
					filters={filters}
					onFilterChange={handleFilterChange}
				/>
			</Card>
			<Card bodyStyle={{ padding: '0px' }}>
				<div className="table-responsive">
					<DataTable
						data={data}
						totalResults={totalResults}
						fetchPreviousPage={fetchPreviousPage}
						fetchNextPage={fetchNextPage}
						currentPage={currentPage}
						isFetching={isFetching}
						columns={columns}
						paginationOnChange={paginationOnChange}
						setShowSizeChange={setShowSizeChange}
						defaultPageSize={pageSize}
						sortByHeader={sortByHeader}
						onSortChange={value =>
							setSortByHeader({ sortBy: value })
						}
						rowSelection={rowSelection}
						selectedRows={selectedRows}
					/>
				</div>
				<UserDraver
					visible={visible}
					close={closeVisible}
					role={role}
					fullname={fullName}
					username={username}
					email={email}
					createdAt={createdAt}
					gender={gender}
					id={id}
				/>
			</Card>
			<Modal
				visible={isGiftModalOpen}
				footer={null}
				onCancel={() => setIsGiftModalOpen(false)}>
				<GiftModalUserAvatarContainer>
					<Avatar src={getSelectedUser?.thumbnail} />
					<UserNameWrapper>
						<GiftModalFullName>
							{getSelectedUser?.fullname}
						</GiftModalFullName>
						<GiftModalUserName>
							{`@${getSelectedUser?.username}`}
						</GiftModalUserName>
					</UserNameWrapper>
				</GiftModalUserAvatarContainer>

				<GiftSelectBoxWrapper>
					<Select
						style={{ width: '100%' }}
						value={getSelectedGift || ' -- 💐 Hediye sec -- '}
						onChange={handleOnGiftSelected}>
						{getProductList?.map((product, i) => {
							return (
								<Select.Option value={product.id} key={i}>
									{product.name.tr}
								</Select.Option>
							);
						})}
					</Select>
				</GiftSelectBoxWrapper>

				{getSelectedGift && (
					<GiftSubmitButton>
						<div
							style={{ width: '100%' }}
							type="default"
							className="gift-button"
							htmlType="submit"
							onClick={hadnleSubmittingGift}>
							Onayla
						</div>
					</GiftSubmitButton>
				)}
			</Modal>
		</>
	);
};
export default UserTable;
