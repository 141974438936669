import React, { useState } from 'react';
import styled from 'styled-components';
import SideMenu from '../../components/organisms/SideMenu';
import TopMenu from '../../components/organisms/TopMenu';
import { device } from '../../utils/device';
import HorizontalMenu from '../../components/organisms/HorizontalMenu';
import { Helmet } from 'react-helmet';
import { Table, Button, Space, Tag, Modal, message } from 'antd';
import theme from '../../theme';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { instanceApi } from 'services/Base/base.instance.services';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const Container = styled.div`
	width: 100%;
	display: flex;
`;

const TopSection = styled.div``;

const SideMenuSection = styled.div`
	position: fixed;
	@media ${device.ipadL} {
		display: none;
	}
`;

const MobileMenu = styled.div`
	display: none;
	width: 100%;
	@media ${device.ipadL} {
		display: block;
	}
`;

const NotificationContent = () => {
	const history = useHistory();
	const queryClient = useQueryClient();

	const { data: notifications, isLoading } = useQuery({
		queryKey: ['notifications'],
		queryFn: () =>
			instanceApi().get('/notifications/all', {
				params: {
					sortBy: 'createdAt:desc',
				},
			}),
	});

	const { mutate: deleteNotification } = useMutation({
		mutationFn: id => instanceApi().delete(`/notifications/global/${id}`),
		onSuccess: () => {
			message.success('Bildirim başarıyla silindi');
			queryClient.invalidateQueries(['notifications']);
		},
		onError: () => {
			message.error('Bildirim silinirken bir hata oluştu');
		},
	});

	const handleDelete = id => {
		Modal.confirm({
			title: 'Bildirimi Sil',
			content: 'Bu bildirimi silmek istediğinize emin misiniz?',
			okText: 'Evet',
			okType: 'danger',
			cancelText: 'Hayır',
			onOk() {
				deleteNotification(id);
			},
		});
	};

	const columns = [
		{
			title: 'Başlık',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: 'Mesaj',
			dataIndex: 'message',
			key: 'message',
		},
		{
			title: 'Tip',
			dataIndex: 'type',
			key: 'type',
			render: type => <Tag color="blue">{type}</Tag>,
		},
		{
			title: 'Oluşturulma Tarihi',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: date => moment(date).format('DD/MM/YYYY HH:mm'),
		},
		{
			title: 'İşlemler',
			key: 'actions',
			render: (_, record) => (
				<Button
					type="primary"
					danger
					onClick={() => handleDelete(record.id)}
					size="small">
					Sil
				</Button>
			),
		},
	];

	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 20,
				}}>
				<div>
					<h2
						style={{
							fontSize: 29,
							color: theme.darkBlue,
							marginBottom: 5,
						}}>
						Bildirimler
					</h2>
					<label style={{ fontSize: 15, color: theme.lightBlue }}>
						Tüm kullanıcılar için oluşturulan bildirimleri buradan
						görüntüleyebilirsiniz.
					</label>
				</div>
				<Button
					type="primary"
					onClick={() => history.push('/notifications/create')}>
					+ Yeni Bildirim Oluştur
				</Button>
			</div>
			<Table
				dataSource={notifications?.data?.results || []}
				columns={columns}
				loading={isLoading}
				rowKey="_id"
				pagination={{
					total: notifications?.data?.totalResults,
					pageSize: notifications?.data?.limit,
					current: notifications?.data?.page,
				}}
			/>
		</div>
	);
};

const NotificationPage = () => {
	const [isToogleActive, setIsToogleActive] = useState(false);
	const marginLeft = isToogleActive ? 90 : 270;

	const Content = styled.div`
		width: 100%;
		margin-top: 100px;
		margin-left: ${marginLeft}px;
		margin-right: 30px;
		@media ${device.ipadL} {
			margin-left: 20px;
		}
	`;

	return (
		<>
			<Helmet>
				<title>Çizgi App - Admin Panel</title>
			</Helmet>
			<TopSection>
				<TopMenu setIsToogleActive={setIsToogleActive} />
			</TopSection>
			<MobileMenu>
				<HorizontalMenu isToogleActive={isToogleActive} />
			</MobileMenu>
			<Container>
				<SideMenuSection>
					<SideMenu
						sideNavTheme={'SIDE_NAV_LIGHT'}
						isToogleActive={isToogleActive}
					/>
				</SideMenuSection>
				<Content>
					<NotificationContent />
				</Content>
			</Container>
		</>
	);
};

export default NotificationPage;
